import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'
import { useAreaText } from './dependencies'
import LaunchIcon from '@material-ui/icons/Launch'

const styles = {
  section: css({
    [mq('sp')]: {
      padding: '0 12px'
    }
  }),
  heading: css({
    marginBottom: 4
  }),
  icon: css({
    verticalAlign: 'sub'
  })
}

interface Props {
  link: { href: string; text: string }
}

export const RelatedSiteByArea: FC<Props> = ({ link }) => {
  const areaText = useAreaText()

  return (
    <section id="relatedSite" css={styles.section}>
      <h2 css={[styles.heading, typography.textL]}>{areaText}の関連サイト</h2>
      <a
        href={link.href}
        css={typography.textM}
        target="_blank"
        rel="noopener noreferrer"
      >
        {areaText}の{link.text}
        <LaunchIcon fontSize="small" css={styles.icon} />
      </a>
    </section>
  )
}
