import { FC } from 'react'
import { AveragePriceTable } from './AveragePriceTable'
import { Sentences } from './Sentences'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { Content } from './contents'
import { dayjs, priceFormatter } from '@lifedot/utils'
import { AreaPriceAverate_Fragment$key } from './__generated__/AreaPriceAverate_Fragment.graphql'
import { useListPage } from '@/hooks/use-list-page'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { Section } from '@/components/Section/Section'

export type AreaFeaturesProps = {
  averagePrices: AreaPriceAverate_Fragment$key
  content: Content
}

const styles = {
  wrap: css({
    display: 'grid',
    gap: 12,
    gridTemplateColumns: '100%',
    backgroundColor: palette.white,
    padding: '12px 40px',
    [mq('sp')]: {
      padding: 12
    }
  })
}

export const AreaPriceAverate: FC<AreaFeaturesProps> = ({
  averagePrices,
  content
}) => {
  const averagePricesData = useFragment(averagePricesFragment, averagePrices)
  const { prefecture } = useListPage()
  const year = dayjs().format('YYYY')

  return (
    <Section title={`【${year}年版】${prefecture.name}お墓平均費用`}>
      <div css={styles.wrap}>
        <h3 css={typography.textL}>
          {`${prefecture.name}のお墓平均価格は${priceFormatter(
            averagePricesData?.price ?? 0,
            { decimalPoints: 0 }
          )}円`}
        </h3>
        {averagePricesData && <AveragePriceTable prices={averagePricesData} />}
        <Sentences contents={content.cost} />
      </div>
    </Section>
  )
}

const averagePricesFragment = graphql`
  fragment AreaPriceAverate_Fragment on CemeteryPriceAverages {
    price
    ...AveragePriceTable_Fragment
  }
`
