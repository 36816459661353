import { FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { makeItems } from './dependencies'
import { PartialDisplayAccordion } from '@lifedot/components/PartialDisplayAccordion'
import { GridLinks } from '@lifedot/components/GridLinks'
import { PrefectureRomas, prefectures } from '@lifedot/constants/prefectures'
import { typography } from '@lifedot/styles/typography'
import { CityLinks_cemeteryCounts_Fragment$key } from './__generated__/CityLinks_cemeteryCounts_Fragment.graphql'
import { CityLinks_cities_Fragment$key } from './__generated__/CityLinks_cities_Fragment.graphql'

interface CityLinksProps {
  prefectureRoma: PrefectureRomas
  citiesLinks: CityLinks_cities_Fragment$key
  cemeteryCounts: CityLinks_cemeteryCounts_Fragment$key
}

export const CityLinks: FC<CityLinksProps> = ({
  prefectureRoma,
  citiesLinks,
  cemeteryCounts
}) => {
  const citiesData = useFragment(citiesFragment, citiesLinks)
  const cemeteryCountData = useFragment(cemeteryCountFragment, cemeteryCounts)

  const items = makeItems(prefectureRoma, citiesData, cemeteryCountData)

  return (
    <PartialDisplayAccordion
      accordionTitle={`${prefectures[prefectureRoma]}の市区町村を全て表示`}
    >
      <h3 css={typography.textM}>
        {prefectures[prefectureRoma]}のエリアから探す
      </h3>
      <GridLinks items={items} />
    </PartialDisplayAccordion>
  )
}

const citiesFragment = graphql`
  fragment CityLinks_cities_Fragment on CityConnection {
    items {
      cityId: id
      sortOrder
      name
    }
  }
`
const cemeteryCountFragment = graphql`
  fragment CityLinks_cemeteryCounts_Fragment on CemeteryCounts {
    countsByCity {
      cityId: id
      count
    }
  }
`
