/**
 * @generated SignedSource<<982a01c5acd385675582f7eaaf05627f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type AreaPriceAverate_Fragment$data = {
  readonly price: number | null
  readonly ' $fragmentSpreads': FragmentRefs<'AveragePriceTable_Fragment'>
  readonly ' $fragmentType': 'AreaPriceAverate_Fragment'
}
export type AreaPriceAverate_Fragment$key = {
  readonly ' $data'?: AreaPriceAverate_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'AreaPriceAverate_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'AreaPriceAverate_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price',
      storageKey: null
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'AveragePriceTable_Fragment'
    }
  ],
  type: 'CemeteryPriceAverages',
  abstractKey: null
}

;(node as any).hash = '7d3b86f01b9d760489748970a7cf8409'

export default node
