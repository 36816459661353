/**
 * @generated SignedSource<<876532228f5c94c45f0a30ebf4d51f22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type StationLinks_stations_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly stationId: number
    readonly railwayLineId: number
    readonly name: string
  }>
  readonly ' $fragmentType': 'StationLinks_stations_Fragment'
}
export type StationLinks_stations_Fragment$key = {
  readonly ' $data'?: StationLinks_stations_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'StationLinks_stations_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'StationLinks_stations_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Station',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'stationId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'railwayLineId',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'StationConnection',
  abstractKey: null
}

;(node as any).hash = 'ee01916ac03d35dda9ab4e6b364346e3'

export default node
