import { router } from '@lifedot/router'
import { GridLinkItemProps } from '@lifedot/components/GridLinks'
import { StationLinks_stations_Fragment$data } from './__generated__/StationLinks_stations_Fragment.graphql'
import { StationLinks_cemeteryCounts_Fragment$data } from './__generated__/StationLinks_cemeteryCounts_Fragment.graphql'

export const makeItems = (
  prefectureRoma: string,
  cities: Omit<StationLinks_stations_Fragment$data, ' $refType'>,
  cemeteryCounts: Omit<StationLinks_cemeteryCounts_Fragment$data, ' $refType'>
): Array<GridLinkItemProps> => {
  const counts = cemeteryCounts.countsByStation.reduce<Record<number, number>>(
    (res, item) => ({ ...res, [item.stationId]: item.count }),
    {}
  )
  return [...cities.items]
    .sort(({ stationId: left }, { stationId: right }) =>
      left > right ? 1 : left < right ? -1 : 0
    )
    .reduce<Array<GridLinkItemProps>>(
      (res, item) => [
        ...res,
        {
          name: `${item.name}駅`,
          url: router.list({
            prefectureRoma,
            stationId: item.stationId
          }),
          disabled: (counts[item.stationId] ?? 0) < 1
        }
      ],
      []
    )
}
