import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { priceFormatter } from '@lifedot/utils'
import { sectionTypes } from '@lifedot/constants/searchChoices'
import { AveragePriceTable_Fragment$key } from './__generated__/AveragePriceTable_Fragment.graphql'

const styles = {
  table: css({
    'td, th': {
      border: `1px solid ${palette.gray2}`,
      textAlign: 'center'
    },
    th: {
      backgroundColor: palette.background,
      fontWeight: 'bold',
      padding: '4px 8px'
    },
    td: {
      padding: 8
    }
  })
}

export interface AveragePriceTableProps {
  prices: AveragePriceTable_Fragment$key
}

export const AveragePriceTable: FC<AveragePriceTableProps> = ({ prices }) => {
  const data = useFragment(
    graphql`
      fragment AveragePriceTable_Fragment on CemeteryPriceAverages {
        averages {
          category_code
          price
        }
      }
    `,
    prices
  )
  const getPrice = (code: string) =>
    data.averages.find(({ category_code }) => category_code === code)?.price ??
    0

  return (
    <table css={styles.table}>
      <tbody>
        <tr>
          {sectionTypes.map(
            ({ name, code }) =>
              getPrice(code) >= 1 && (
                <th key={name} css={typography.textS}>
                  {name}
                </th>
              )
          )}
        </tr>
        <tr>
          {sectionTypes.map(({ code }) => (
            <PriceTableRow price={getPrice(code)} code={code} key={code} />
          ))}
        </tr>
      </tbody>
    </table>
  )
}

const priceTableRowStyle = {
  marker: css({
    fontWeight: 'bold',
    background: palette.marker,
    padding: 2,
    wordBreak: 'break-word'
  }),
  caption: css({
    color: palette.gray6,
    display: 'block'
  })
}

interface PriceTableRowProps {
  price: number
  code: string
}

const PriceTableRow: FC<PriceTableRowProps> = ({ price, code }) => {
  if (price < 1) return null
  return (
    <td>
      <span css={[typography.textL, priceTableRowStyle.marker]}>
        {priceFormatter(price, { decimalPoints: 0 })}円
      </span>
      {code === 'normal' && (
        <span css={[typography.textXS, priceTableRowStyle.caption]}>
          ※墓石代別
        </span>
      )}
    </td>
  )
}
