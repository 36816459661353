/**
 * @generated SignedSource<<be66b2165ca53071d69dcad7508a26cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CityLinks_cities_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly cityId: number
    readonly sortOrder: number
    readonly name: string
  }>
  readonly ' $fragmentType': 'CityLinks_cities_Fragment'
}
export type CityLinks_cities_Fragment$key = {
  readonly ' $data'?: CityLinks_cities_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'CityLinks_cities_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CityLinks_cities_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'City',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'sortOrder',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CityConnection',
  abstractKey: null
}

;(node as any).hash = '748e732750de4ded446ba268b9df109c'

export default node
