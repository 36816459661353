/**
 * @generated SignedSource<<635449bc20c51bfaf25bd33325df54dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type UsefulPostsList_posts$data = {
  readonly items: ReadonlyArray<{
    readonly postId: number
  }>
  readonly ' $fragmentSpreads': FragmentRefs<'PostList_Fragment'>
  readonly ' $fragmentType': 'UsefulPostsList_posts'
}
export type UsefulPostsList_posts$key = {
  readonly ' $data'?: UsefulPostsList_posts$data
  readonly ' $fragmentSpreads': FragmentRefs<'UsefulPostsList_posts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'UsefulPostsList_posts',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'PostList_Fragment'
    },
    {
      alias: null,
      args: null,
      concreteType: 'Post',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'postId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'PostResult',
  abstractKey: null
}

;(node as any).hash = 'a416a0fed3c73306bc8f42cde8362c46'

export default node
