import { createContext, FC, ReactNode } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import {
  CemeteryReviewsContextProvider_Fragment$data,
  CemeteryReviewsContextProvider_Fragment$key
} from './__generated__/CemeteryReviewsContextProvider_Fragment.graphql'

export interface CemeteryReviewsContextValue {
  fragment: CemeteryReviewsContextProvider_Fragment$data | null
}

export const CemeteryReviewsContext =
  createContext<CemeteryReviewsContextValue>({
    fragment: null
  })

interface CemeteryReviewsContextProviderProps {
  fragment: CemeteryReviewsContextProvider_Fragment$key
  children: ReactNode
}

export const CemeteryReviewsContextProviderFragment: FC<
  CemeteryReviewsContextProviderProps
> = ({ children, fragment }) => {
  const data = useFragment(
    graphql`
      fragment CemeteryReviewsContextProvider_Fragment on Cemetery {
        reviews {
          age
          average_score
          gender
          created_at
          answers {
            text
            code
          }
        }
      }
    `,
    fragment
  )
  return (
    <CemeteryReviewsContext.Provider value={{ fragment: data }}>
      {children}
    </CemeteryReviewsContext.Provider>
  )
}
