import dayjs from 'dayjs'
import { router } from '@lifedot/router'
import { NewsCemeteries_Fragment$data } from './__generated__/NewsCemeteries_Fragment.graphql'

interface Item {
  header: string
  links: { href: string; content: string }[]
}

export const makeItem = (
  updatedCemeteries: Omit<NewsCemeteries_Fragment$data, ' $refType'>
): Item | undefined => {
  const { items } = updatedCemeteries

  const item = items[0]
  if (!item) return undefined

  return {
    header: `${dayjs(item.updated_at).format(
      'YYYY-MM-DD'
    )}【更新】霊園情報を更新しました`,
    links: items.map(({ cemeteryId, name }) => {
      return {
        href: router.cemetery(cemeteryId),
        content: name
      }
    })
  }
}
