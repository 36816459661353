import { FC } from 'react'
import { PartialDisplayAccordion } from '@lifedot/components/PartialDisplayAccordion'
import { GridLinks } from '@lifedot/components/GridLinks'
import { typography } from '@lifedot/styles/typography'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { CityLinksList_cities$key } from './__generated__/CityLinksList_cities.graphql'
import { useCityLinksItems } from './dependencies'
import { useListPage } from '@/hooks/use-list-page'

interface CityLinksProps {
  cities: CityLinksList_cities$key
}

export const CityLinksList: FC<CityLinksProps> = ({ cities }) => {
  const data = useFragment(citiesFragment, cities)
  const { prefecture } = useListPage()
  const items = useCityLinksItems(data)

  return (
    <PartialDisplayAccordion
      accordionTitle={`${prefecture.name}の市区町村を全て表示`}
    >
      <h3 css={typography.textM}>
        {prefecture.name}内から選択中の詳細条件と同じお墓を探す
      </h3>
      <GridLinks items={items} />
    </PartialDisplayAccordion>
  )
}

const citiesFragment = graphql`
  fragment CityLinksList_cities on CityWithCemeteryCountConnection {
    items {
      cityId: id
      name
      cemeteryCount
    }
  }
`
