/**
 * @generated SignedSource<<4064a4ede13eebbc7edfcb5dbb1f4662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type AveragePriceTable_Fragment$data = {
  readonly averages: ReadonlyArray<{
    readonly category_code: string
    readonly price: number | null
  }>
  readonly ' $fragmentType': 'AveragePriceTable_Fragment'
}
export type AveragePriceTable_Fragment$key = {
  readonly ' $data'?: AveragePriceTable_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'AveragePriceTable_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'AveragePriceTable_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'PriceAverage',
      kind: 'LinkedField',
      name: 'averages',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'category_code',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'price',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryPriceAverages',
  abstractKey: null
}

;(node as any).hash = 'd2a67cf54bda58183beacab9a5b56037'

export default node
