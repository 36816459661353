import { FC } from 'react'
import { Card, CardContent } from '@lifedot/atoms/Card'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { ReviewCardHeader } from './ReviewCardHeader'
import { ReviewCardDetails } from './ReviewCardDetails'
import { CemeteryReviewsContextProviderFragment } from '@/hooks/use-cemetery-reviews'
import { ReviewCard_Fragment$key } from './__generated__/ReviewCard_Fragment.graphql'

interface ReviewCardProps {
  cemetery: ReviewCard_Fragment$key
}

export const ReviewCard: FC<ReviewCardProps> = ({ cemetery }) => {
  const data = useFragment(
    graphql`
      fragment ReviewCard_Fragment on Cemetery {
        ...ReviewCardHeader_Fragment
        ...ReviewCardDetails_Fragment
        ...CemeteryReviewsContextProvider_Fragment
      }
    `,
    cemetery
  )

  return (
    <Card round={1} outlineWidth={1}>
      <CardContent spacing={3}>
        <ReviewCardHeader cemetery={data} />
        <CemeteryReviewsContextProviderFragment fragment={data}>
          <ReviewCardDetails cemetery={data} />
        </CemeteryReviewsContextProviderFragment>
      </CardContent>
    </Card>
  )
}
