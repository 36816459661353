import { router } from '@lifedot/router'
import { GridLinkItemProps } from '@lifedot/components/GridLinks'
import { CityLinks_cities_Fragment$data } from './__generated__/CityLinks_cities_Fragment.graphql'
import { CityLinks_cemeteryCounts_Fragment$data } from './__generated__/CityLinks_cemeteryCounts_Fragment.graphql'

export const makeItems = (
  prefectureRoma: string,
  cities: Omit<CityLinks_cities_Fragment$data, ' $refType'>,
  cemeteryCounts: Omit<CityLinks_cemeteryCounts_Fragment$data, ' $refType'>
): Array<GridLinkItemProps> => {
  const counts = cemeteryCounts.countsByCity.reduce<Record<number, number>>(
    (res, item) => ({ ...res, [item.cityId]: item.count }),
    {}
  )
  return [...cities.items]
    .sort(({ sortOrder: left }, { sortOrder: right }) =>
      left > right ? 1 : left < right ? -1 : 0
    )
    .reduce<Array<GridLinkItemProps>>(
      (res, item) => [
        ...res,
        {
          name: item.name,
          url: router.list({ prefectureRoma, cityId: item.cityId }),
          disabled: (counts[item.cityId] ?? 0) < 1
        }
      ],
      []
    )
}
