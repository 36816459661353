import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { Button } from '@lifedot/atoms/Button'
import { typography } from '@lifedot/styles/typography'
import Link, { LinkProps } from 'next/link'

const styles = {
  link: css({
    display: 'block',
    maxWidth: 400,
    margin: '20px auto 0',
    textDecoration: 'none'
  })
}

export interface AnchorLinkProps {
  url: LinkProps['href']
  text: ReactNode
  nofollow?: boolean
}

export const AnchorLink: FC<AnchorLinkProps> = ({ url, text, nofollow }) => (
  <Link href={url} passHref prefetch={false}>
    <a css={styles.link} rel={nofollow ? 'nofollow' : undefined}>
      <Button as="div" mainText={<span css={typography.textM}>{text}</span>} />
    </a>
  </Link>
)
