import { FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { makeItem } from './dependencies'
import Link from 'next/link'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { NewsCemeteries_Fragment$key } from './__generated__/NewsCemeteries_Fragment.graphql'
import { Section } from '@/components/Section/Section'

const styles = {
  root: css({
    backgroundColor: palette.white,
    padding: '12px 40px',
    [mq('sp')]: {
      padding: 12
    },
    ul: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 8,
      marginTop: 8,
      [mq('sp')]: {
        gridTemplateColumns: '1fr'
      }
    }
  })
}

interface NewsCemeteriesProps {
  updatedCemeteries: NewsCemeteries_Fragment$key
}

export const NewsCemeteries: FC<NewsCemeteriesProps> = ({
  updatedCemeteries
}) => {
  const data = useFragment(updatedCemeteriesFragment, updatedCemeteries)
  const item = makeItem(data)

  if (item === undefined) return null

  return (
    <Section title={'霊園・墓地新着ニュース'}>
      <div css={styles.root}>
        <p css={typography.textM}>
          <b>{item.header}</b>
        </p>
        <List
          icon={
            <FiberManualRecordIcon
              style={{ color: palette.main, fontSize: 12 }}
            />
          }
        >
          {item.links.map(({ href, content }) => (
            <ListItem key={href}>
              <Link href={href} passHref prefetch={false}>
                <a css={typography.textS}>{content}</a>
              </Link>
            </ListItem>
          ))}
        </List>
      </div>
    </Section>
  )
}

const updatedCemeteriesFragment = graphql`
  fragment NewsCemeteries_Fragment on CemeteryResult {
    items {
      cemeteryId: id
      name
      updated_at
    }
  }
`
