import { CemeteryReviewsContextProvider_Fragment$data } from '../../../../hooks/use-cemetery-reviews/__generated__/CemeteryReviewsContextProvider_Fragment.graphql'

export type Review = CemeteryReviewsContextProvider_Fragment$data['reviews'][0]

export const mainText = (review: Review): string | undefined | null =>
  review.answers.find(({ code }) => code === 'management')?.text

export const purchasePriceString = (
  review: Review
): string | undefined | null =>
  review.answers.find(({ code }) => code === 'price')?.text
