/**
 * @generated SignedSource<<9aaf97109215fcf0e2df4be9102ca1bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CityLinks_cemeteryCounts_Fragment$data = {
  readonly countsByCity: ReadonlyArray<{
    readonly cityId: number
    readonly count: number
  }>
  readonly ' $fragmentType': 'CityLinks_cemeteryCounts_Fragment'
}
export type CityLinks_cemeteryCounts_Fragment$key = {
  readonly ' $data'?: CityLinks_cemeteryCounts_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'CityLinks_cemeteryCounts_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CityLinks_cemeteryCounts_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CountWithID',
      kind: 'LinkedField',
      name: 'countsByCity',
      plural: true,
      selections: [
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'count',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryCounts',
  abstractKey: null
}

;(node as any).hash = 'e0a791566f9ead311e0bb5881d2b8e84'

export default node
