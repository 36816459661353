import { GetStaticPaths, GetStaticProps } from 'next'
import { FC } from 'react'
import { PrefectureRomas, prefectures } from '@lifedot/constants/prefectures'
import { sorts } from '@lifedot/constants/sort'
import { initEnvironment } from '@lifedot/relay'
import {
  prefectureListPage_indexQuery,
  prefectureListPage_indexQuery$data
} from '@/queries/__generated__/prefectureListPage_indexQuery.graphql'
import { fetchQuery } from 'relay-runtime'
import { useQuery } from 'relay-hooks/lib/useQuery'
import { prefectureListPageQuery } from '@/queries/prefectureListPage'
import { Hakajimai } from '@/components/Hakajimai'
import { CommonContentsDummy } from '@/contents/CommonContentsDummy'
import { CommonContents } from '@/contents/CommonContents'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { ListPageContextProvider } from '@/hooks/use-list-page'
import { useRouter } from 'next/router'
import { getSearchParamsByQuery } from '@/hooks/use-list-page/dependencies'
import { makeRelatedSiteLinkForPrefecture } from '@/utils/makeRelatedSiteByAreaLink'
import { AreaFaq, Faq } from '@/components/Faq'
import { CityLinksList } from '@/components/CityLinksList'
import { ConditionLinksList } from '@/components/ConditionLinksList'
import { RelatedSiteByArea } from '@/components/RelatedSiteByArea'
import { ShopLinksList } from '@/components/ShopLinksList/ShopLinksList'
import { CityLinks } from '@/components/CityLinks'
import { StationLinks } from '@/components/StationLinks'
import { UsefulPostsList } from '@/components/UsefulPostsList'
import { NewsCemeteries } from '@/components/NewsCemeteries'
import { AreaFeatures, AreaPriceAverate } from '@/components/AreaFeatures'
import { Content } from '@/components/AreaFeatures/contents'
import { Contents } from '@/components/Faq/contents'
import { Section } from '@/components/Section/Section'
import { AveragePrice } from '@/components/AveragePrice'

export const getStaticPaths: GetStaticPaths = async () => {
  if (
    !['production', 'preview'].includes(process.env.NEXT_PUBLIC_BUILD_ENV ?? '')
  )
    return {
      paths: [],
      fallback: 'blocking'
    }

  const paths = Object.keys(prefectures).map((prefecture) => ({
    params: { prefecture }
  }))
  return {
    paths: paths,
    fallback: false
  }
}

export const getStaticProps: GetStaticProps<
  ListPageProps,
  {
    prefecture: string
  }
> = async ({ params }) => {
  const prefectureRoma = params?.prefecture ?? ''
  const relatedSiteLinkByArea = makeRelatedSiteLinkForPrefecture(prefectureRoma)
  const areaFeaturesContent =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require(`../../../../components/AreaFeatures/contents/${prefectureRoma}.ts`)
      .default as Content

  const faqContents =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require(`../../../../components/Faq/contents/${prefectureRoma}.ts`)
      .default as Contents

  try {
    const { environment, relaySSR }: ReturnType<typeof initEnvironment> =
      initEnvironment()
    await fetchQuery<prefectureListPage_indexQuery>(
      environment,
      prefectureListPageQuery,
      {
        prefectureRoma,
        from: 0,
        sort: sorts['default']
      },
      {}
    ).toPromise()
    const [relayData] = await relaySSR.getCache()

    const [cacheString, payload] = relayData
    if (!payload.data || payload.data.cemeteries.total < 1)
      return {
        notFound: true
      }

    return {
      props: {
        prefecture: {
          roma: prefectureRoma,
          name: prefectures[prefectureRoma as PrefectureRomas]
        },
        relatedSiteLinkByArea,
        areaFeaturesContent,
        faqContents,
        relayData:
          !relayData || !('json' in payload)
            ? null
            : [[cacheString, payload.json]]
      },
      revalidate: Number(process.env.DEFAULT_REVALIDATE_TERM)
    }
  } catch (e) {
    if (typeof e === 'object' && e !== null && 'res' in e) {
      if (typeof (e as { res?: unknown }).res === 'object')
        console.log((e as { res?: { errors?: unknown } }).res?.errors)
    } else if (e instanceof Error) console.log(e.message)

    // ページの生成自体は成功したことにしておき、次のアクセスで再生成し直す
    return {
      props: {
        prefecture: {
          roma: prefectureRoma,
          name: prefectures[prefectureRoma as PrefectureRomas]
        },
        relatedSiteLinkByArea,
        areaFeaturesContent,
        faqContents
      },
      revalidate: 1
    }
  }
}

interface ListPageProps {
  prefecture: {
    roma: string
    name: string
  }
  relatedSiteLinkByArea: { href: string; text: string }
  areaFeaturesContent: Content
  faqContents: Contents
}

const ListPage: FC<ListPageProps> = (props) => {
  const { query } = useRouter()
  const { prefecture } = props
  const {
    cityIds,
    stationIds,
    features,
    cemeteryTypes,
    facilities,
    sectionTypes,
    sects,
    sectionCapacities,
    priceMax,
    priceMin,
    priceRange,
    from,
    sort
  } = getSearchParamsByQuery(query)
  const { error, data } = useQuery<prefectureListPage_indexQuery>(
    prefectureListPageQuery,
    {
      prefectureRoma: props.prefecture.roma,
      cities: cityIds,
      stations: stationIds,
      features,
      cemeteryTypes,
      facilities,
      sectionTypes,
      sectionCapacities,
      sects,
      priceMax,
      priceMin,
      priceRange,
      from,
      sort
    }
  )

  const _page = query['page']
  const page = Number(Array.isArray(_page) ? _page[0] : _page) ?? 1
  const isDisplayingOnlyPrefectureList = !isNaN(page)

  if (error || !data) return <CommonContentsDummy />

  return (
    <ListPageContextProvider
      {...data}
      prefecture={prefecture}
      pagination={data.cemeteries}
    >
      {isDisplayingOnlyPrefectureList ? (
        <CommonContents data={data} prefecture={props.prefecture}>
          <AddMarginWrapper spacing={4}>
            <AveragePrice {...data} />
            <Faq {...data} />
            <ConditionLinksList {...data} />
            <CityLinksList {...data} />
            <ShopLinksList fragmentRef={data.shopCities} />
            <Hakajimai />
            <RelatedSiteByArea link={props.relatedSiteLinkByArea} />
          </AddMarginWrapper>
        </CommonContents>
      ) : (
        <CommonContents data={data} prefecture={props.prefecture}>
          <AddMarginWrapper spacing={5}>
            <AreaPriceAverate
              averagePrices={data.areaAveratePrices}
              content={props.areaFeaturesContent}
            />
            <AreaFaq contents={props.faqContents} />
            <AreaFeatures
              {...data}
              content={props.areaFeaturesContent}
              prefectureRoma={prefecture.roma}
              prefectureName={prefecture.name}
            />
            <Hakajimai />
            <LinksList prefecture={prefecture} data={data} />
            <UsefulPostsList
              posts={data.usefulPosts}
              title="お墓に関するお役立ち記事"
            />
            <NewsCemeteries updatedCemeteries={data.updatedCemeteries} />
            <RelatedSiteByArea link={props.relatedSiteLinkByArea} />
          </AddMarginWrapper>
        </CommonContents>
      )}
    </ListPageContextProvider>
  )
}

type LinksListProps = {
  prefecture: {
    name: string
    roma: string
  }
  data: prefectureListPage_indexQuery$data
}

const LinksList: FC<LinksListProps> = ({ prefecture, data }) => {
  const prefectureRoma = prefecture.roma as PrefectureRomas

  return (
    <Section title={`${prefecture.name}のお墓・墓地を探す`}>
      <AddMarginWrapper spacing={2}>
        <CityLinks prefectureRoma={prefectureRoma} {...data} />
        <ConditionLinksList {...data} />
        <CityLinksList {...data} />
        <StationLinks prefectureRoma={prefectureRoma} {...data} />
        <ShopLinksList fragmentRef={data.shopCities} />
      </AddMarginWrapper>
    </Section>
  )
}

export default ListPage
