/**
 * @generated SignedSource<<1abc8a818e4c95008f4d19898e636644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ReviewCardDetails_Fragment$data = {
  readonly cemeteryId: number
  readonly ' $fragmentType': 'ReviewCardDetails_Fragment'
}
export type ReviewCardDetails_Fragment$key = {
  readonly ' $data'?: ReviewCardDetails_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ReviewCardDetails_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ReviewCardDetails_Fragment',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '278aac8e49e642ae818b6d6d5c8b271d'

export default node
