import { FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { makeItems } from './dependencies'
import { PartialDisplayAccordion } from '@lifedot/components/PartialDisplayAccordion'
import { GridLinks } from '@lifedot/components/GridLinks'
import { PrefectureRomas, prefectures } from '@lifedot/constants/prefectures'
import { typography } from '@lifedot/styles/typography'
import { StationLinks_stations_Fragment$key } from './__generated__/StationLinks_stations_Fragment.graphql'
import { StationLinks_cemeteryCounts_Fragment$key } from './__generated__/StationLinks_cemeteryCounts_Fragment.graphql'

interface StationLinksProps {
  prefectureRoma: PrefectureRomas
  stations: StationLinks_stations_Fragment$key
  cemeteryCounts: StationLinks_cemeteryCounts_Fragment$key
}

export const StationLinks: FC<StationLinksProps> = ({
  prefectureRoma,
  stations,
  cemeteryCounts
}) => {
  const stationsData = useFragment(stationsFragment, stations)
  const cemeteryCountsData = useFragment(cemeteryCountsFragment, cemeteryCounts)

  const items = makeItems(prefectureRoma, stationsData, cemeteryCountsData)

  return (
    <PartialDisplayAccordion
      accordionTitle={`${prefectures[prefectureRoma]}の駅を全て表示`}
    >
      <h3 css={typography.textM}>{prefectures[prefectureRoma]}の駅から探す</h3>
      <GridLinks items={items} />
    </PartialDisplayAccordion>
  )
}

const stationsFragment = graphql`
  fragment StationLinks_stations_Fragment on StationConnection {
    items {
      stationId: id
      railwayLineId
      name
    }
  }
`

const cemeteryCountsFragment = graphql`
  fragment StationLinks_cemeteryCounts_Fragment on CemeteryCounts {
    countsByStation {
      stationId: id
      count
    }
  }
`
