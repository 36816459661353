import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { markup } from '@lifedot/utils'

const styles = css({
  ':not(:last-of-type)': {
    marginBottom: 8
  }
})
export interface SentencesProps {
  contents: string[]
}

export const Sentences: FC<SentencesProps> = ({ contents }) => (
  <>
    {contents.map((content, index) => (
      <p
        css={[typography.textM, styles]}
        key={index}
        dangerouslySetInnerHTML={markup(content)}
      />
    ))}
  </>
)
