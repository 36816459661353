/**
 * @generated SignedSource<<f19d2d2fe8136893f51b77f5e3a67deb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CityLinksList_cities$data = {
  readonly items: ReadonlyArray<{
    readonly cityId: number
    readonly name: string
    readonly cemeteryCount: number
  }>
  readonly ' $fragmentType': 'CityLinksList_cities'
}
export type CityLinksList_cities$key = {
  readonly ' $data'?: CityLinksList_cities$data
  readonly ' $fragmentSpreads': FragmentRefs<'CityLinksList_cities'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CityLinksList_cities',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CityWithCemeteryCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cemeteryCount',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CityWithCemeteryCountConnection',
  abstractKey: null
}

;(node as any).hash = '5f33549706726e181423e9506b4783bd'

export default node
