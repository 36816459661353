import { FC, ReactNode } from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { css } from '@emotion/react'

const styles = {
  content: css({
    flex: '1 1',
    marginLeft: 8
  })
}

export interface ImageContentLayoutProps {
  image: ReactNode
  content: ReactNode
  link?: boolean
}

export const ImageContentLayout: FC<ImageContentLayoutProps> = ({
  image,
  content,
  link = false
}) => (
  <>
    <figure>{image}</figure>
    <div css={styles.content}>{content}</div>
    {link && <ChevronRightIcon fontSize="small" />}
  </>
)
