import { css } from '@emotion/react'
import { Rating } from '@lifedot/atoms/Rating'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'
import { ImageContentLayout } from '@lifedot/components/ImageContentLayout'
import Image from 'next/image'
import dayjs from 'dayjs'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { ChevronRight } from '@material-ui/icons'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { mainText, purchasePriceString } from './dependencies'
import {
  ageDecadeFormatter,
  genderI18n,
  getReviewImagePath
} from '@lifedot/utils'
import { useCemeteryBestPerformanceReview } from '@/hooks/use-cemetery-reviews'
import { ReviewCardDetails_Fragment$key } from './__generated__/ReviewCardDetails_Fragment.graphql'

const styles = {
  review: css({
    background: palette.gray1,
    borderRadius: 4,
    padding: 12,
    marginTop: 12,
    [mq('sp')]: {
      margin: '12px -12px -12px',
      borderRadius: 0
    }
  }),
  reviewHeader: css({
    display: 'flex',
    marginBottom: 12
  }),
  more: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: palette.main03
  }),
  rate: css({
    display: 'flex',
    alignItems: 'center'
  }),
  leftMargin: css({
    marginLeft: 12
  })
}

interface ReviewCardDetailsProps {
  cemetery: ReviewCardDetails_Fragment$key
}

export const ReviewCardDetails: FC<ReviewCardDetailsProps> = ({ cemetery }) => {
  const data = useFragment(
    graphql`
      fragment ReviewCardDetails_Fragment on Cemetery {
        cemeteryId: id
      }
    `,
    cemetery
  )
  const review = useCemeteryBestPerformanceReview()

  if (!review) return null
  return (
    <div css={styles.review}>
      <div css={[typography.textS, styles.reviewHeader]}>
        <ImageContentLayout
          image={
            <Image
              src={getReviewImagePath(review)}
              width={45}
              height={45}
              alt={`${ageDecadeFormatter(review.age)}・${genderI18n(
                review.gender
              )}`}
            />
          }
          content={
            <>
              <p>
                <b>
                  {ageDecadeFormatter(review.age)}・{genderI18n(review.gender)}
                </b>
                {purchasePriceString(review) && (
                  <span css={styles.leftMargin}>
                    購入費用：<b>{purchasePriceString(review)}</b>
                  </span>
                )}
              </p>
              <div css={styles.rate}>
                <Rating point={review.average_score} />
                <span css={styles.leftMargin}>
                  回答日：{dayjs(review.created_at).format('YYYY年M月')}
                </span>
              </div>
            </>
          }
        />
      </div>
      <p css={typography.textS}>{mainText(review)}</p>
      <Link
        href={{
          hash: 'a-review',
          pathname: router.cemetery(data.cemeteryId)
        }}
        passHref
        prefetch={false}
      >
        <a css={[typography.textS, styles.more]}>
          続きを見る
          <ChevronRight fontSize="small" />
        </a>
      </Link>
    </div>
  )
}
