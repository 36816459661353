import { graphql } from 'relay-runtime'

export const prefectureListPageQuery = graphql`
  query prefectureListPage_indexQuery(
    $prefectureRoma: String!
    $cities: [Int!]
    $stations: [Int!]
    $sectionTypes: [String!]
    $cemeteryTypes: [String!]
    $sects: [String!]
    $sectionCapacities: [String!]
    $facilities: [String!]
    $features: [String!]
    $priceMax: String
    $priceMin: String
    $priceRange: PriceRangeInput
    $from: Int = 0
    $sort: CemeterySortInput
  ) {
    cemeteries: listCemeteries(
      filter: {
        prefectures: [$prefectureRoma]
        cities: $cities
        stations: $stations
        cemeteryTypes: $cemeteryTypes
        sectionTypes: $sectionTypes
        sects: $sects
        sectionCapacities: $sectionCapacities
        facilities: $facilities
        features: $features
        priceMax: $priceMax
        priceMin: $priceMin
        priceRange: $priceRange
      }
      page: { from: $from, size: 20 }
      sort: $sort
    ) {
      ...CemeteryList_cemeteries
      ...StructuredData_cemeteries
      ...ListPageContextProvider_pagination
      ...NoCemeteries_cemeteries
      ...ThirdPartyAdData_cemeteries
      ...SeoHead_cemeteries
    }
    averages: averageCemeteryPrices(
      filter: { prefectures: [$prefectureRoma] }
    ) {
      ...useAveragePrices_averages
    }
    areaAveratePrices: averageCemeteryPrices(
      filter: { prefectures: [$prefectureRoma] }
    ) {
      ...AreaPriceAverate_Fragment
    }
    rankingCemeteries: listCemeteries(
      filter: { prefectures: [$prefectureRoma] }
      page: { from: 0, size: 5 }
      sort: { field: "ranking", order: "desc" }
    ) {
      ...Faq_rankingCemeteries
      ...Ranking_rankingCemeteries
      ...PopularCemeteries_rankingCemeteries
    }
    highScoreCemeteries: listCemeteries(
      filter: { prefectures: [$prefectureRoma], requestable: true }
      page: { from: 0, size: 3 }
      sort: { field: "enquete_score", order: "desc" }
    ) {
      ...HighScoreCemeteries_highScoreCemeteries
      ...AreaFeatures_highScoreCemeteries_Fragment
    }
    cities: listCitiesWithCemeteryCountByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 999
    ) {
      ...CityLinksList_cities
    }
    conditions: listSearchChoicesWithCemeteryCount(
      cemeteryFilter: { prefectures: [$prefectureRoma] }
      limit: 99
    ) {
      ...ConditionLinksList_conditions
    }
    sectionTypes: listSearchChoicesWithCemeteryCount(
      attributeCode: "section_type"
      cemeteryFilter: {
        prefectures: [$prefectureRoma]
        cities: $cities
        stations: $stations
        cemeteryTypes: $cemeteryTypes
        # ここに sectionTypes がないのはミスではない。sectionTypes に対しての件数を求めるので、sectionTypesは指定してはいけない。
        sects: $sects
        sectionCapacities: $sectionCapacities
        facilities: $facilities
        features: $features
        priceMax: $priceMax
        priceMin: $priceMin
      }
    ) {
      ...SearchSectionTypeLinks_sectionTypes
    }
    shopCities: listCitiesWithShopCountByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 999
    ) {
      ...ShopLinksList_Fragment
    }
    cemeteryCounts: countCemeteries(
      filter: { prefectures: [$prefectureRoma] }
    ) {
      ...CityLinks_cemeteryCounts_Fragment
      ...StationLinks_cemeteryCounts_Fragment
    }
    citiesLinks: listCitiesByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 999
    ) {
      ...CityLinks_cities_Fragment
    }
    stations: listStationsByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 9999
    ) {
      ...StationLinks_stations_Fragment
    }
    usefulPosts: listPrefectureFeaturePosts(
      prefectureRoma: $prefectureRoma
      page: { size: 20 }
    ) {
      ...UsefulPostsList_posts
    }
    updatedCemeteries: listCemeteries(
      filter: { prefectures: [$prefectureRoma] }
      sort: { field: "updated_at", order: "desc" }
    ) {
      ...NewsCemeteries_Fragment
    }
  }
`
