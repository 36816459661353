/**
 * @generated SignedSource<<8004cfa0f922160d38bb342a0e1c999d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ReviewCard_Fragment$data = {
  readonly ' $fragmentSpreads': FragmentRefs<
    | 'ReviewCardHeader_Fragment'
    | 'ReviewCardDetails_Fragment'
    | 'CemeteryReviewsContextProvider_Fragment'
  >
  readonly ' $fragmentType': 'ReviewCard_Fragment'
}
export type ReviewCard_Fragment$key = {
  readonly ' $data'?: ReviewCard_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ReviewCard_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ReviewCard_Fragment',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ReviewCardHeader_Fragment'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ReviewCardDetails_Fragment'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'CemeteryReviewsContextProvider_Fragment'
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = 'e06fa503c177a05745e7692132ed94da'

export default node
