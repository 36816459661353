import arraySort from 'array-sort'
import { router } from '@lifedot/router'
import { CityLinksList_cities$data } from './__generated__/CityLinksList_cities.graphql'
import { useMemo } from 'react'
import { useListPage } from '@/hooks/use-list-page'

export const useCityLinksItems = (
  cities: CityLinksList_cities$data
): {
  name: string
  url: string
}[] => {
  const { prefecture, condition } = useListPage()

  return useMemo(
    () =>
      arraySort(
        cities.items.reduce<{ name: string; url: string }[]>(
          (res, { name, cityId, cemeteryCount }) => {
            if (cemeteryCount < 1) return res
            const url = condition
              ? router.list({
                  prefectureRoma: prefecture.roma,
                  cityId,
                  condition: {
                    code: condition.code,
                    attributeCode: condition.attributeCode
                  }
                })
              : router.list({ prefectureRoma: prefecture.roma, cityId })
            return [
              ...res,
              {
                name,
                url
              }
            ]
          },
          []
        ),
        'url'
      ),
    [cities.items, condition, prefecture.roma]
  )
}
