import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { Accordion } from '@lifedot/atoms/Accordion'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = {
  section: css({
    background: palette.white,
    borderBottom: `1px solid ${palette.gray2}`,
    ':first-of-type': {
      borderTop: `1px solid ${palette.gray2}`
    }
  }),
  content: css({
    padding: '20px 40px',
    boxSizing: 'border-box',
    backgroundColor: palette.background,
    [mq('sp')]: {
      padding: '0 12px 20px'
    }
  }),
  title: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 40px',
    [mq('sp')]: {
      padding: '8px 12px'
    }
  }),
  icon: css({
    '[open] &': {
      transform: 'rotate(180deg)'
    }
  })
}

export interface AccordionWrapperProps {
  title: string
  children: ReactNode
}

export const AccordionWrapper: FC<AccordionWrapperProps> = ({
  title,
  children
}) => (
  <section css={styles.section}>
    <Accordion
      isOpen
      title={
        <h3 css={[typography.textL, styles.title]}>
          {title}
          <ExpandMoreIcon css={styles.icon} />
        </h3>
      }
    >
      <div css={styles.content}>{children}</div>
    </Accordion>
  </section>
)
