import { FC } from 'react'
import { css } from '@emotion/react'
import { router } from '@lifedot/router'
import { AccordionWrapper } from './AccordionWrapper'
import { Sentences } from './Sentences'
import { ReviewCard } from './ReviewCard'
import { AnchorLink } from './AnchorLink'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { Content } from './contents'
import {
  AttributeCode,
  CemeteryTypeCode,
  SectCode,
  SectionTypeCode
} from '@lifedot/constants/searchChoices'
import { AreaFeatures_highScoreCemeteries_Fragment$key } from './__generated__/AreaFeatures_highScoreCemeteries_Fragment.graphql'
import { Section } from '@/components/Section/Section'

const styles = {
  cardList: css({
    margin: '12px 0',
    '> :not(:first-of-type)': {
      marginTop: 12
    }
  }),
  button: css({
    maxWidth: 400,
    margin: '12px auto 0'
  })
}
export type AreaFeaturesProps = {
  highScoreCemeteries: AreaFeatures_highScoreCemeteries_Fragment$key
  prefectureName: string
  prefectureRoma: string
  content: Content
}

export const AreaFeatures: FC<AreaFeaturesProps> = ({
  highScoreCemeteries,
  prefectureName,
  prefectureRoma,
  content
}) => {
  const highScoreCemeteriesData = useFragment(
    highScoreCemeteriesFragment,
    highScoreCemeteries
  )

  return (
    <Section title={`${prefectureName}のお墓の特徴`}>
      <AccordionWrapper title={`${prefectureName}の特徴と霊園・墓地について`}>
        <Sentences contents={content.feature} />
      </AccordionWrapper>

      <AccordionWrapper title={`${prefectureName}で口コミ評価の高いお墓`}>
        <Sentences contents={content.reviewHead} />
        <div css={styles.cardList}>
          {highScoreCemeteriesData.items.map((cemetery, index) => (
            <ReviewCard key={index} cemetery={cemetery} />
          ))}
        </div>
        <Sentences contents={content.review} />
        <AnchorLink
          url={router.list({ prefectureRoma, order: 'enquete_score' })}
          text={
            <>
              口コミ評価順に
              <br />
              {prefectureName}のお墓一覧を見る
            </>
          }
          nofollow
        />
      </AccordionWrapper>

      <AccordionWrapper title={content.sectionTypeTitle}>
        <Sentences contents={content.sectionType} />
        <AnchorLink
          url={router.list({
            prefectureRoma,
            condition: {
              attributeCode: AttributeCode.SECTION_TYPE,
              code: SectionTypeCode.NORMAL
            }
          })}
          text={`${prefectureName}の一般墓の一覧を見る`}
        />
      </AccordionWrapper>

      <AccordionWrapper title={content.cemeteryTypeTitle}>
        <Sentences contents={content.cemeteryType} />
        <AnchorLink
          url={router.list({
            prefectureRoma,
            condition: {
              attributeCode: AttributeCode.CEMETERY_TYPE,
              code: CemeteryTypeCode.TEMPLE
            }
          })}
          text={`${prefectureName}の寺院墓地の一覧を見る`}
        />
      </AccordionWrapper>

      <AccordionWrapper title={content.sectTitle}>
        <Sentences contents={content.sect} />
        <AnchorLink
          url={router.list({
            prefectureRoma,
            condition: {
              attributeCode: AttributeCode.SECT,
              code: SectCode.NONE
            }
          })}
          text={`${prefectureName}の宗教不問の一覧を見る`}
        />
      </AccordionWrapper>
    </Section>
  )
}

const highScoreCemeteriesFragment = graphql`
  fragment AreaFeatures_highScoreCemeteries_Fragment on CemeteryResult {
    items {
      ...ReviewCard_Fragment
    }
  }
`
