import { FC } from 'react'
import { css } from '@emotion/react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import { PostList } from '../PostList'
import { UsefulPostsList_posts$key } from './__generated__/UsefulPostsList_posts.graphql'

const styles = {
  layout: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 12,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 8,
      padding: '0 12px'
    }
  }),
  anchor: css({
    textDecoration: 'none',
    display: 'flex'
  }),
  heading: css({
    marginBottom: 4,
    [mq('sp')]: {
      padding: '0 12px'
    }
  })
}

interface UsefulPostsProps {
  posts: UsefulPostsList_posts$key
  title?: string
}

export const UsefulPostsList: FC<UsefulPostsProps> = ({ posts, title }) => {
  const data = useFragment(postsFragment, posts)
  if (data.items.length < 1) return null

  return (
    <>
      <h3 css={[typography.textL, styles.heading]}>{title}</h3>
      <PostList posts={data} />
    </>
  )
}

const postsFragment = graphql`
  fragment UsefulPostsList_posts on PostResult {
    ...PostList_Fragment
    items {
      postId: id
    }
  }
`
