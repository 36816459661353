import { useContext, useMemo } from 'react'
import { CemeteryReviewsContext } from './CemeteryReviewsContextProvider'
import { CemeteryReviewsContextProvider_Fragment$data } from './__generated__/CemeteryReviewsContextProvider_Fragment.graphql'

export const useCemeteryBestPerformanceReview = ():
  | CemeteryReviewsContextProvider_Fragment$data['reviews'][0]
  | undefined => {
  const { fragment } = useContext(CemeteryReviewsContext)
  return useMemo(
    () =>
      [...(fragment?.reviews ?? [])].sort(
        ({ average_score: left }, { average_score: right }) =>
          left < right ? 1 : left > right ? -1 : 0
      )[0],
    [fragment]
  )
}
