import { css } from '@emotion/react'
import { Rating } from '@lifedot/atoms/Rating'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'
import { ImageContentLayout } from '@lifedot/components/ImageContentLayout'
import Image from 'next/image'
import Link from 'next/link'
import { router } from '@lifedot/router'
import RoomIcon from '@material-ui/icons/Room'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { cemeteryMainImage } from '@lifedot/utils'
import { ReviewCardHeader_Fragment$key } from './__generated__/ReviewCardHeader_Fragment.graphql'

const styles = {
  root: css({
    display: 'flex'
  }),
  summary: css({
    display: 'flex',
    [mq('sp')]: {
      flexDirection: 'column'
    }
  }),
  review: css({
    display: 'flex',
    alignItems: 'center',
    marginRight: 12
  }),
  rate: css({
    marginLeft: 8
  }),
  map: css({
    color: palette.gray6
  }),
  address: css({
    display: 'flex',
    alignItems: 'center'
  })
}

interface ReviewCardHeaderProps {
  cemetery: ReviewCardHeader_Fragment$key
}

export const ReviewCardHeader: FC<ReviewCardHeaderProps> = ({ cemetery }) => {
  const data = useFragment(fragment, cemetery)

  return (
    <div css={styles.root}>
      <ImageContentLayout
        image={
          <Image
            src={cemeteryMainImage(data.main_image).path}
            width={75}
            height={60}
            alt={cemeteryMainImage(data.main_image).alt}
          />
        }
        content={
          <>
            <h4 css={typography.textL}>
              <Link
                href={router.cemetery(data.cemeteryId)}
                passHref
                prefetch={false}
              >
                <a>{data.name}</a>
              </Link>
            </h4>
            <div css={[typography.textS, styles.summary]}>
              <div css={styles.review}>
                総合評価
                <div css={styles.rate}>
                  <Rating point={data.review_summary.total_score ?? 0} />
                </div>
              </div>
              <span css={styles.address}>
                <RoomIcon fontSize="small" css={styles.map} />
                {data.address}
              </span>
            </div>
          </>
        }
      />
    </div>
  )
}

const fragment = graphql`
  fragment ReviewCardHeader_Fragment on Cemetery {
    cemeteryId: id
    name
    review_summary {
      total_score
    }
    main_image {
      alt
      path
    }
    address
  }
`
