/**
 * @generated SignedSource<<bcd14e03a3300794d4cf59f67164fa11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ReviewCardHeader_Fragment$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly review_summary: {
    readonly total_score: number | null
  }
  readonly main_image: {
    readonly alt: string | null
    readonly path: string | null
  } | null
  readonly address: string | null
  readonly ' $fragmentType': 'ReviewCardHeader_Fragment'
}
export type ReviewCardHeader_Fragment$key = {
  readonly ' $data'?: ReviewCardHeader_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ReviewCardHeader_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ReviewCardHeader_Fragment',
  selections: [
    {
      alias: 'cemeteryId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReviewSummary',
      kind: 'LinkedField',
      name: 'review_summary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'total_score',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryImage',
      kind: 'LinkedField',
      name: 'main_image',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'alt',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'path',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'address',
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '61766fe587bf9247cb9e215299227370'

export default node
