/**
 * @generated SignedSource<<e3456934c735cf99655c3f29a4de3cce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type AreaFeatures_highScoreCemeteries_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ReviewCard_Fragment'>
  }>
  readonly ' $fragmentType': 'AreaFeatures_highScoreCemeteries_Fragment'
}
export type AreaFeatures_highScoreCemeteries_Fragment$key = {
  readonly ' $data'?: AreaFeatures_highScoreCemeteries_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'AreaFeatures_highScoreCemeteries_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'AreaFeatures_highScoreCemeteries_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Cemetery',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ReviewCard_Fragment'
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = '16ce3d346274a7fdd64897ea0d31c91b'

export default node
